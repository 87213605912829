module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#e91e1e","theme_color":"#e91e1e","display":"minimal-ui","icon":"src/images/tg-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"13592563d543402afb73963b0ab9f273"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PG69M62","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function () {\n          return {\n            event: 'consent_settings',\n            ad_storage: window?.localStorage?.ad_storage === 'true',\n            analytics_storage: window?.localStorage?.analytics_storage === 'true',\n            functionality_storage: window?.localStorage?.functionality_storage === 'true',\n          };\n        }"},"routeChangeEventName":"virtualPageView"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["SF Pro Rounded"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www-d.apps.tiklagelsin.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
